<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
import SaveButton from "@/components/buttons/save-button";
import CancelButton from "@/components/buttons/cancel-button";

export default {
	components: {
		MessageModal,
		ConfirmationModal,
		InputGroup,
		SelectGroup,
		SaveButton,
		CancelButton
	},
	props: {
		tutorInfo: Object,
	},
	data() {
		const id = this.$route.params.id;
        let modeName;
		
		if (this.$route.path.includes('view')) {
			modeName = 'View';
		} else {
			modeName = id === 'add' ? 'Add' : 'Edit';
		}

		return {
			id: id,
			modeName,
			data: {
				name: '',
				nric: '',
				gender: '',
				phone: '',
				email: '',
				dob: '',
				age: '',
				nationality: 'Singapore',
				special_needs_exp: 0,
				application_status: '',
				remarks: '',
				reside_in_sg: 0,
				highest_education_qualification: '',
				is_blacklisted: '',
			},
			nationalityOptions: [],
			applicationStatusOptions: [
                { name: 'Approved', value: 'approved' },
                { name: 'Rejected', value: 'rejected' },
				{ name: 'Pending', value: 'pending' },
            ],
			errors: {},
			saveBtnDisabled: false,
		};
	},
	mounted() {
		this.retrieveCountries().then((countries) => {
			this.nationalityOptions = countries.map((country) => ({
				name: country.name,
				value: country.name,
			}));
		});

		console.log('TUI', this.tutorInfo)
	},
	computed: {
		nameInput() {
			return this.modeName === 'Add' ? this.data.name : this.tutorInfo.name;
		},
		nricInput() {
			return this.modeName === 'Add' ? this.data.nric : this.tutorInfo.nric;
		},
		genderInput() {
			return this.modeName === 'Add' ? this.data.gender : this.tutorInfo.gender;
		},
		phoneInput() {
			return this.modeName === 'Add' ? this.data.phone : this.tutorInfo.phone;
		},
		emailInput() {
			return this.modeName === 'Add' ? this.data.email : this.tutorInfo.email;
		},
		dobInput() {
			return this.modeName === 'Add' ? this.data.dob : this.tutorInfo.dob;
		},
		maxDate() {
            const currentDate = new Date();
            const maxYear = currentDate.getFullYear() - 18;
            const maxMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const maxDay = currentDate.getDate().toString().padStart(2, '0');
            return `${maxYear}-${maxMonth}-${maxDay}`;
        },
		ageInput: {
			get() {
				return this.modeName === 'Add' ? this.data.age : this.tutorInfo.age;
			},
			set(value) {
				if (this.modeName === 'Add') {
					this.data.age = value;
				} else {
					this.tutorInfo.age = value;
				}
			}
		},
		nationalityInput() {
			return this.modeName === 'Add' ? this.data.nationality : this.tutorInfo.nationality;
		},
		experienceInput() {
			return this.modeName === 'Add' ? this.data.special_needs_exp : this.tutorInfo.special_needs_exp;
		},
		applicationStatusInput() {
			return this.modeName === 'Add' ? this.data.application_status : this.tutorInfo.application_status;
		},
		heqInput() {
			return this.modeName === 'Add' ? this.data.highest_education_qualification : this.tutorInfo.highest_education_qualification;
		},
		remarkInput() {
			return this.modeName === 'Add' ? this.data.remarks : this.tutorInfo.remarks;
		},
		blacklistInput() {
			return this.modeName === 'Add' ? this.data.is_blacklisted : this.tutorInfo.is_blacklisted;
		},
	},
	methods: {
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		async retrieveCountries() {
            this.loading = true;
            let grades = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/api/country`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    grades = result['data'].countries;
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return grades;
        },
		updateName(value) {
			if (this.modeName === 'Add') {
				this.data.name = value;
			} else {
				this.tutorInfo.name = value;
			}
		},
		updateNric(value) {
			if (this.modeName === 'Add') {
				this.data.nric = value;
			} else {
				this.tutorInfo.nric = value;
			}
		},
		updateGender(value) {
			if (this.modeName === 'Add') {
				this.data.gender = value;
			} else {
				this.tutorInfo.gender = value;
			}
		},
		updatePhone(value) {
			if (this.modeName === 'Add') {
				this.data.phone = value;
			} else {
				this.tutorInfo.phone = value;
			}
		},
		updateEmail(value) {
			if (this.modeName === 'Add') {
				this.data.email = value;
			} else {
				this.tutorInfo.email = value;
			}
		},
		updateDob(value) {
			if (this.modeName === 'Add') {
				this.data.dob = value;
			} else {
				this.tutorInfo.dob = value;
			}

			this.calculateAge(value);
		},
		updateAge(value) {
			if (this.modeName === 'Add') {
				this.data.age = value;
			} else {
				this.tutorInfo.age = value;
			}
		},
		updateNationality(value) {
			if (this.modeName === 'Add') {
				this.data.nationality = value;
			} else {
				this.tutorInfo.nationality = value;
			}
		},
		updateExp(value) {
			if (this.modeName === 'Add') {
				this.data.special_needs_exp = value;
			} else {
				this.tutorInfo.special_needs_exp = value;
			}
		},
		updateReside(value) {
			if (this.modeName === 'Add') {
				this.data.reside_in_sg = value;
			} else {
				this.tutorInfo.reside_in_sg = value;
			}
		},
		updateApplicationStatus(value) {
			if (this.modeName === 'Add') {
				this.data.application_status = value;
			} else {
				this.tutorInfo.application_status = value;
			}
		},
		updateHeq(value) {
			if (this.modeName === 'Add') {
				this.data.highest_education_qualification = value;
			} else {
				this.tutorInfo.highest_education_qualification = value;
			}
		},
		updateRemarks(value) {
			if (this.modeName === 'Add') {
				this.data.remarks = value;
			} else {
				this.tutorInfo.remarks = value;
			}
		},
		updateBlacklist(value) {
			if (this.modeName === 'Add') {
				this.data.is_blacklisted = value;
			} else {
				this.tutorInfo.is_blacklisted = value;
			}
		},
		calculateAge(dob) {
			if (dob) {
				const dobDate = new Date(dob);
				const today = new Date();
				let age = today.getFullYear() - dobDate.getFullYear();
				const monthDiff = today.getMonth() - dobDate.getMonth();
				if (monthDiff < 0 || (monthDiff == 0 && today.getDate() < dobDate.getDate())) {
					age--;
				}
				this.ageInput = age;
			} else {
				this.ageInput = null;
			}
		},
		async save() {
			this.saveBtnDisabled = true;
			this.errors = {};
			let result;
			
			if (this.modeName === 'Add') {
				this.data.status = 1;
				const requestData = this.data;	
				
				result = await axios.post(`${process.env.VUE_APP_APIURL}/tutor`, requestData, {
					headers: authHeader(),
				});
			} else {
				const requestData = this.tutorInfo;
				requestData.step = 1;

				delete requestData.docs;
				delete requestData.profile_pic;
				delete requestData.edu_qualification;
				delete requestData.specialised_cert;
				delete requestData.service_details;
				delete requestData.services;
				delete requestData.service_providers;
console.log('requestData', requestData)
				result = await axios.put(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, requestData, {
					headers: authHeader(),
				});
console.log('result', result)
			}

			if (result) {
				if (result.data['code'] === 'success') {
					this.saveBtnDisabled = false;
					if (this.modeName === 'Add') {
						window.location.href = `${window.location.origin}/service_professional/form/${result.data.data.tutor.id}`
					} else {
						this.$refs.messageModal.showModal('Your record has been updated successfully');
					}
				} else if (result.data['code'] === 'invalid_field') {
					this.saveBtnDisabled = false;
					this.errors = result.data['errors'];
				}
			}
		},
		async cancel() {
			this.$router.push({ name: "Tutor" });
		}
	},
};
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<ConfirmationModal ref="confirmationModal" />

		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" stlye="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row">
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Full name (as per NRIC)</p>
							<p>{{ nameInput }}</p>
						</div>
						<InputGroup v-else type="text" id="name" displayName="Full name (as per NRIC)" :value="nameInput" @input="updateName" :error="errors.name" />
					</div>
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Last four digits of NRIC</p>
							<p>{{ nricInput }}</p>
						</div>
						<InputGroup v-else type="text" id="nric" displayName="Last four digits of NRIC" :value="nricInput" @input="updateNric" :error="errors.nric" />
					</div>
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Gender</p>
							<p>{{ capitalizeFirstLetter(tutorInfo.gender) }}</p>
						</div>
						<div v-else class="form-group">
							<div class="d-flex align-items-center flex-wrap">
								<label class="mb-0 mr-3 d-block w-100">Gender</label>
								<div class="d-flex mt-3 radio">
									<input type="radio" id="male_gender" value="male" name="gender" role="button" :checked="modeName === 'Add' ? data.gender === 'male' : tutorInfo.gender === 'male'" @change="updateGender($event.target.checked ? 'male' : '')" :error="errors.gender">  
									<label class="mb-0 ml-2 mr-3" for="male_gender" role="button">Male</label>
									<input type="radio" class="ml-5" id="female_gender" value="female" name="gender" role="button" :checked="modeName === 'Add' ? data.gender === 'female' : tutorInfo.gender === 'female'" @change="updateGender($event.target.checked ? 'female' : '')">  
									<label class="mb-0 ml-2 mr-3" for="female_gender" role="button">Female</label>
								</div>
							</div>
						</div>
						<div v-if="errors.gender" role="alert" class="alert alert-danger mt-4">
							Please select gender
						</div>
					</div>

					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Contact number</p>
							<p>{{ phoneInput }}</p>
						</div>
						<InputGroup v-else type="text" id="contact_number" displayName="Contact number" :value="phoneInput" @input="updatePhone" :error="errors.phone" />
					</div>
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Email address</p>
							<p>{{ emailInput }}</p>
						</div>
						<InputGroup v-else type="text" id="email" displayName="Email address" :value="emailInput" @input="updateEmail" :error="errors.email" />
					</div>

					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">DOB</p>
							<p>{{ dobInput }}</p>
						</div>
						<InputGroup v-else type="date" id="dob" displayName="DOB" :value="dobInput" @input="updateDob" :error="errors.dob" :max="maxDate" />
					</div>
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Age</p>
							<p>{{ ageInput }}</p>
						</div>
						<InputGroup v-else type="text" id="age" displayName="Age" :value="ageInput" @input="updateAge" :error="errors.age" :readOnly="true" />
					</div>
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Nationality</p>
							<p>{{ nationalityInput }}</p>
						</div>
						<SelectGroup v-else id="nationality" displayName="Nationality" :value="nationalityInput" @input="updateNationality" :error="errors.nationality" :options="nationalityOptions" />
					</div>
					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Status</p>
							<p>{{ capitalizeFirstLetter(applicationStatusInput) }}</p>
						</div>
						<SelectGroup v-else id="status" displayName="Status" :value="applicationStatusInput" @input="updateApplicationStatus" :error="errors.application_status" :options="applicationStatusOptions" />
					</div>

					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Do you have experience working with children with special needs?</p>
							<p>{{ tutorInfo.special_needs_exp == 1 ? 'Yes' : 'No' }}</p>
						</div>
						<div v-else class="form-group">
							<div class="d-flex align-items-center flex-wrap">
								<label class="mb-0 mr-3 d-block w-100">Do you have experience working with children with special needs?</label>
								<div class="d-flex mt-3 radio">
									<input type="radio" id="exp_yes" value="1" name="special_needs_exp" role="button" :checked="modeName === 'Add' ? data.special_needs_exp == 1 : tutorInfo.special_needs_exp == 1" @change="updateExp($event.target.checked ? 1 : '')" :error="errors.special_needs_exp">  
									<label class="mb-0 ml-2 mr-3" for="exp_yes" role="button">Yes</label>
									<input type="radio" class="ml-5" id="exp_no" value="0" name="special_needs_exp" role="button" :checked="modeName === 'Add' ? data.special_needs_exp == 0 : tutorInfo.special_needs_exp == 0" @change="updateExp($event.target.checked ? 0 : '')">  
									<label class="mb-0 ml-2 mr-3" for="exp_no" role="button">No</label>
								</div>
							</div>
						</div>
						<div v-if="errors.special_needs_exp" role="alert" class="alert alert-danger mt-4">
							Please select
						</div>
					</div>

					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Are you currently residing in Singapore?</p>
							<p>{{ tutorInfo.reside_in_sg == 1 ? 'Yes' : 'No' }}</p>
						</div>
						<div v-else class="form-group">
							<div class="d-flex align-items-center flex-wrap">
								<label class="mb-0 mr-3 d-block w-100">Are you currently residing in Singapore?</label>
								<div class="d-flex mt-3 radio">
									<input type="radio" id="reside_yes" value="1" name="reside_in_sg" role="button" :checked="modeName === 'Add' ? data.reside_in_sg == 1 : tutorInfo.reside_in_sg == 1" @change="updateReside($event.target.checked ? 1 : '')" :error="errors.reside_in_sg">  
									<label class="mb-0 ml-2 mr-3" for="reside_yes" role="button">Yes</label>
									<input type="radio" class="ml-5" id="reside_no" value="0" name="reside_in_sg" role="button" :checked="modeName === 'Add' ? data.reside_in_sg == 0 : tutorInfo.reside_in_sg == 0" @change="updateReside($event.target.checked ? 0 : '')">  
									<label class="mb-0 ml-2 mr-3" for="reside_no" role="button">No</label>
								</div>
							</div>
						</div>
						<div v-if="errors.reside_in_sg" role="alert" class="alert alert-danger mt-4">
							Please select
						</div>
					</div>

					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Highest Education Qualification</p>
							<p>{{ tutorInfo.highest_education_qualification ?? '-' }}</p>
						</div>
						<div v-else class="form-group">
							<InputGroup type="textarea" id="highest_education_qualification" displayName="Highest Education Qualification" :value="heqInput" @input="updateHeq" />
						</div>
						<div v-if="errors.highest_education_qualification" role="alert" class="alert alert-danger mt-4">
							Please fill this field
						</div>
					</div>

					<div class="col-12 col-md-4">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Remarks</p>
							<p>{{ remarkInput ?? '-' }}</p>
						</div>
						<div v-else>
							<InputGroup type="textarea" id="remarks" displayName="Remarks" :value="remarkInput" @input="updateRemarks" />
						</div>
					</div>

					<div class="col-12">
						<div v-if="modeName === 'View'">
							<p class="label mb-0">Blacklisted?</p>
							<p>{{ tutorInfo.is_blacklisted == 1 ? 'Yes' : 'No' }}</p>
						</div>
						<div v-else class="form-group">
							<div class="d-flex align-items-center flex-wrap">
								<label class="mb-0 mr-3 d-block w-100">Blacklisted?</label>
								<div class="d-flex mt-3 radio">
									<input type="radio" id="blacklist_yes" value="1" name="is_blacklisted" role="button" :checked="modeName === 'Add' ? data.is_blacklisted == 1 : tutorInfo.is_blacklisted == 1" @change="updateBlacklist($event.target.checked ? 1 : '')">  
									<label class="mb-0 ml-2 mr-3" for="blacklist_yes" role="button">Yes</label>
									<input type="radio" class="ml-5" id="blacklist_no" value="0" name="is_blacklisted" role="button" :checked="modeName === 'Add' ? data.is_blacklisted == 0 : tutorInfo.is_blacklisted == 0" @change="updateBlacklist($event.target.checked ? 0 : '')">  
									<label class="mb-0 ml-2 mr-3" for="blacklist_no" role="button">No</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="modeName !== 'View'" class="divider"></div>

				<div v-if="modeName !== 'View'" class="row mt-2">
					<div class="col-12" style="text-align: right;">
						<CancelButton @click="cancel" />
						<SaveButton @click="save" :disabled="saveBtnDisabled" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>